import React from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Form, Formik } from 'formik';

import { handleSubmit } from './formHandlers.js';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  progressBar: {
    width: '100%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Updates(props) {
  const classes = useStyles();
  const fileInputRef = React.createRef();

  return (
    <>
      <div className={classes.progressBar}>
      </div>
      <Paper className={classes.paper}>
        <Formik
          initialValues={{ securityManual: ''}}
          onSubmit={(...args) => handleSubmit(props, ...args)}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <input
                    className={classes.input}
                    id="securityManual"
                    type="file"
                    name="file"
                    ref={fileInputRef}
                    onChange={e => { setFieldValue('securityManual', e.currentTarget.files[0]) }}
                  />
                  <label htmlFor="securityManual">
                    <IconButton
                      color="primary"
                      aria-label="upload security manual file"
                      component="span"
                      size="medium"
                      onKeyDown={(e) => (e.keyCode === 32 || e.keyCode === 13) && fileInputRef.current.click()}
                    >
                      <InsertDriveFileIcon />
                    </IconButton>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component="span">
                    {values.securityManual.name || "Click on the file icon to select a file."}
                  </Typography>
                </Grid>
                <Grid item>
                  <div className={classes.wrapper}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};
