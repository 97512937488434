import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  field: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function DownloadVehicleData(props) {
  const savedVIN = sessionStorage.getItem('VIN') || localStorage.getItem('VIN') || '';
  const classes = useStyles();
  const [VINText, setVINText] = useState(savedVIN);
  const [VIN, setVIN] = useState(savedVIN);

  useEffect(() => props.onVehicleChange(savedVIN), [props, savedVIN]);

  function handleClick() {
    if (VIN !== VINText) {
      props.onVehicleChange(VINText);
    }
    setVIN(VINText);
    sessionStorage.setItem('VIN', VINText);
    localStorage.setItem('VIN', VINText);
  }

  return (
    <>
      <Grid container spacing={2} justify="flex-end">
        <TextField
          className={classes.field}
          value={VINText}
          onChange={e => setVINText(e.target.value)}
          placeholder="VIN"
        ></TextField>
        <Button
          type="submit"
          variant="contained"
          className={classes.field}
          color="primary"
          disabled={VINText === VIN || (!Boolean(VINText))}
          onClick={handleClick}
        >
          UPDATE
        </Button>
      </Grid>
    </>
  );
};
