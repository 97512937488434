import axios from 'axios';
import urljoin from 'url-join';

import * as constants from '../constants';
import errors from './errors';
import makeWS from './ws';

const BASE_URL = urljoin(process.env.REACT_APP_BASE_URL || window.location.origin, 'api');

const URLS = Object.freeze({
  LOGIN: urljoin(BASE_URL, 'login'),
  LATEST_OTA: urljoin(BASE_URL, 'ota', 'latest'),
  OTA: urljoin(BASE_URL, 'ota', '/'),
  DOWNLOAD_VEHICLE_DATA: urljoin(BASE_URL, 'vehicleData', 'download'),
  SECURITY_MANUAL: urljoin(BASE_URL, 'securityManual', '/'),
  WS: urljoin(window.location.origin.replace('http', 'ws'), 'ws', 'user', '/'),
});

const ws = makeWS(URLS.WS);

async function makeRequest(requestFn) {
  try {
    const resp = await requestFn();
    return resp;
  } catch (err) {
    if (err.response) {
      const { status, data: rawData } = err.response;
      let data;
      // Handle ArrayBuffer response if there are errors.
      if (rawData.constructor === ArrayBuffer) {
        data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(rawData)));
      } else {
        data = rawData;
      }
      if (status === 400) {
        throw new errors.Error400(data);
      } else if (status === 401) {
        throw new errors.Error401(data);
      } else if (status === 404) {
        throw new errors.Error404(data);
      } else if (status === 500) {
        throw new errors.Error500(data);
      } else {
        throw new errors.APIError(data);
      }
    }
    throw err;
  }
}

async function login(values) {
  const { data: { token } } = await makeRequest(async () => {
    const resp = await axios.post(URLS.LOGIN, values);
    return resp;
  });
  return token;
}

async function getLatestOTA(token) {
  const { data: { latestOTAUpdate } } = await makeRequest(async () => {
    const URL = urljoin(URLS.LATEST_OTA, `?hwType=${constants.HW_TYPES.IVI}`);
    const resp = await axios.get(URL, { headers: { Authorization: `Bearer ${token}` } });
    return resp;
  });
  return latestOTAUpdate;
}

async function putOTA(token, values) {
  await makeRequest(async () => {
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const resp = await axios.put(URLS.OTA, formData, config);
    return resp;
  });
}

async function getOTAList(token, values) {
  const { data } = await makeRequest(async () => {
    const config = {
      params: values,
      headers: { Authorization: `Bearer ${token}` },
    };
    const resp = await axios.get(URLS.OTA, config);
    return resp;
  });
  return data;
}

async function downloadVehicleData(token, values) {
  const { data } = await makeRequest(async () => {
    const config = {
      params: values,
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'arraybuffer',
    };
    const resp = await axios.get(URLS.DOWNLOAD_VEHICLE_DATA, config);
    return resp;
  });
  return data;
}

async function postSecurityManual(token, values) {
  await makeRequest(async () => {
    const formData = new FormData();
    Object.keys(values).forEach(key => formData.append(key, values[key]));
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const resp = await axios.post(URLS.SECURITY_MANUAL, formData, config);
    return resp;
  });
}

export default {
  login,
  getLatestOTA,
  getOTAList,
  putOTA,
  downloadVehicleData,
  postSecurityManual,
  errors,
  URLS,
  ws,
};
