import React from 'react';
import { Redirect } from 'react-router-dom';

import URLS from '../../urls';

export default props => (
  <>
    { props.token ? props.children : <Redirect to={URLS.LOGIN} /> }
  </>
);
