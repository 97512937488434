import APIError from './apiError';
import Error400 from './error400';
import Error401 from './error401';
import Error404 from './error404';
import Error500 from './error500';

export default {
  APIError,
  Error400,
  Error401,
  Error404,
  Error500,
};
