import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { useState } from 'react';

import KeyValueTable from './KeyValueTable';

const useTabpanelStyles = makeStyles(theme => ({
  tabpanel: {
    flexGrow: 2,
  }
}));
function TabPanel(props) {
  const classes = useTabpanelStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabpanel}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
  },
  theadSkeleton: {
    width: '100%',
    height: '50px',
    marginBottom: '3px',
  },
  tbodySkeleton: {
    width: '100%',
    height: '800px',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'visible',
  },
}));

function createData(data) {
  return Object.keys(data).map(key => ({ key: key, value: data[key] }));
}

export default function VehicleData({ isLoading, vehicleData }) {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  if (isLoading) {
    return (
      <>
        <Skeleton className={classes.theadSkeleton} variant="rect" />
        <Skeleton className={classes.tbodySkeleton} variant="rect" />
      </>
    )
  }

  if (!vehicleData) {
    return (
      <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
        No data available for this vehicle!
      </Typography>
    );
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Tabs
          variant="scrollable"
          orientation="vertical"
          value={currentTab}
          onChange={handleChange}
          aria-label="Vehicle data"
          className={classes.tabs}
        >
          {Object.keys(vehicleData).sort().map((key, index) => (
            <Tab label={key} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
        {Object.keys(vehicleData).sort().map((key, index) => (
          <TabPanel value={currentTab} index={index} key={key}>
            {<KeyValueTable rows={createData(vehicleData[key])} />}
          </TabPanel>
        ))}
      </div>
    </Paper>
  );
};
