import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Route, Switch, Redirect } from 'react-router-dom';

import URLS from '../../urls';

// import Home from './Home';
// import NewOTA from './NewOTA';
// import OTAList from './OTAList';
import SecurityManual from './SecurityManual';
import VehicleData from './VehicleData';
import DownloadVehicleData from './DownloadVehicleData';
import MainListItems from './MainListItems';
import VehicleSelector from './VehicleSelector';
import VehicleTracker from './VehicleTracker';
import { useVehicleData } from './hooks';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        HummingbirdEV
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function Dashboard({ api, token, onLogout, onError, onSuccess }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [VIN, setVIN] = React.useState('');
  const { isLoading, vehicleData } = useVehicleData(api.ws, VIN, token);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
            Dashboard
          </Typography>
          <IconButton
            color='inherit'
            title='logout'
            onClick={onLogout}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
          <MainListItems />
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='lg' className={classes.container}>
          <Grid container spacing={2}>
            <VehicleSelector onVehicleChange={setVIN} />
            <Switch>
              <Route
                path={URLS.VEHICLE_DATA}
                exact={true}
                render={props => (
                  <VehicleData
                    {...props}
                    api={api}
                    token={token}
                    vehicleData={vehicleData}
                    isLoading={isLoading}
                    onLogout={onLogout}
                    onError={onError}
                  />
                )}
              />
              <Route
                path={URLS.DOWNLOAD_VEHICLE_DATA}
                exact={true}
                render={props => (
                  <DownloadVehicleData
                    {...props}
                    VIN={VIN}
                    api={api}
                    onError={onError}
                    onSuccess={onSuccess}
                  />
                )}
              />
              <Route
                path={URLS.TRACK_VEHICLE}
                exact={true}
                render={props => (
                  <VehicleTracker
                    {...props}
                    vehicleData={vehicleData}
                    api={api}
                    onError={onError}
                    onSuccess={onSuccess}
                  />
                )}
              />
              <Route
                path={URLS.SECURITY_MANUAL}
                exact={true}
                render={props => (
                  <SecurityManual
                    {...props}
                    api={api}
                    onError={onError}
                    onSuccess={onSuccess}
                  />
                )}
              />
              <Redirect to={URLS.VEHICLE_DATA} />
            </Switch>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
