import APIError from './apiError';

function objectIsEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

// Reduce multiple field errors to one.
function transformErrors(errors) {
  const { fieldErrors, nonFieldErrors } = errors;
  const newErrors = {};
  if (fieldErrors && (!objectIsEmpty(fieldErrors))) {
    newErrors.fieldErrors = {};
    Object.keys(fieldErrors).forEach((field) => {
      newErrors.fieldErrors[field] = fieldErrors[field][0];
    });
  }
  if (nonFieldErrors && nonFieldErrors.length > 0) {
    newErrors.nonFieldErrors = errors.nonFieldErrors;
  }
  return newErrors;
}

export default class Error400 extends APIError {
  constructor(errors, status=400) {
    errors = transformErrors(errors);
    super(errors, status);
  }
}
