import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import './App.css';

import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import SignIn from './components/SignIn';

import api from './api';
import URLS from './urls';
import { useLogin } from './hooks';

const theme = createMuiTheme({
  // palette: { primary: { main: '#1e1e1e' }, secondary: { main: '#676767' } },
});

function successSnackbar(enqueueSnackbar, msg) {
  if (msg) { enqueueSnackbar(msg, { autoHideDuration: 1500, variant: 'success' }); }
}

function errorSnackbar(enqueueSnackbar, msg) {
  if (!msg) { msg = 'Unknown error occured.'; }
  enqueueSnackbar(msg, { autoHideDuration: 1500, variant: 'error' });
}

function App(props) {
  const { token, onLogin, onLogout } = useLogin();
  const onSuccess = msg => successSnackbar(props.enqueueSnackbar, msg);
  const onError = msg => errorSnackbar(props.enqueueSnackbar, msg);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Switch>
            <Route
              path={URLS.LOGIN}
              exact={true}
              render={props => (
                <SignIn
                  {...props}
                  token={token}
                  onLogin={onLogin}
                  api={api}
                  onError={onError}
                  onSuccess={onSuccess}
                />
              )}
            />
            <PrivateRoute token={token} >
              <Route
                path={URLS.HOME}
                render={props => (
                  <Dashboard
                    {...props}
                    token={token}
                    onLogout={onLogout}
                    api={api}
                    onError={onError}
                    onSuccess={onSuccess}
                  />
                )}
              />
            </PrivateRoute>
          </Switch>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );

}

export default withSnackbar(App);
