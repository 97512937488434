import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  username: yup.string().email().required(),
  password: yup.string().required(),
});

export async function handleSubmit({ api, onLogin, onError }, values, { setErrors }) {
  try {
    // const token = await api.login(values);
    if (values.username === 'admin@example.com' && values.password === 'password') {
      const token = 'DUMMY_TOKEN';
      onLogin(token);
    }
  } catch (err) {
    if (err instanceof api.errors.Error400) {
      const { fieldErrors, nonFieldErrors } = err.errors;
      setErrors(fieldErrors);
      if (nonFieldErrors) { onError(nonFieldErrors[0]); }
    } else {
      onError();
    }
  }
}
