import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
  },
  skeleton: {
    width: '100%',
    height: '400px',
  },
  map: {
    width: '100%',
    height: '400px',
  },
}));

function getLocation(vehicleData) {
  let location;
  if (vehicleData && vehicleData.Location) {
    location = { lat: Number(vehicleData.Location.Latitude), lng: Number(vehicleData.Location.Longitude) };
  }
  return location;
}

function createMap(mapDiv) {
  const map = new window.google.maps.Map(mapDiv, {
    zoom: 12,
    center: { lat: 0, lng: 0 },
    disableDefaultUI: true,
  });
  return map;
}

function updateMarker(marker, map, loc) {
  if (loc) {
    if (!marker) {
      marker = new window.google.maps.Marker({ position: loc, map })
    } else {
      marker.setPosition(loc);
      marker.setMap(map);
    }
    map.panTo(loc);
  } else if (marker) {
    // No location. Remove existing marker from map.
    marker.setMap(null);
  }
  return marker;
}

export default function VehicleTracker({ vehicleData }) {
  const classes = useStyles();
  const mapDivRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!window.google) {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener('load', () => {
        mapRef.current = createMap(mapDivRef.current);
        setIsLoading(false);
      });
    } else {
      mapRef.current = createMap(mapDivRef.current);
      setIsLoading(false);
    }
  }, [mapDivRef]);

  useEffect(() => {
    if (!isLoading) {
      const loc = getLocation(vehicleData);
      markerRef.current = updateMarker(markerRef.current, mapRef.current, loc);
    }
  }, [isLoading, vehicleData, mapRef, markerRef]);

  return (
    <>
      {isLoading && <Skeleton className={classes.skeleton} variant="rect" />}
      <Paper className={classes.paper} style={{ "display": (isLoading ? 'none' : 'flex') }}>
        <div
          id="map"
          ref={mapDivRef}
          className={classes.map}
        />
      </Paper>
    </>
  )
};
