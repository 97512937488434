import * as yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

/**
 * Set date to start of day or end of day in UTC and return ISO formatted string.
 */
function normalizeDate(date, endOfDay = false) {
  const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  let utcDateStr;
  if (endOfDay) {
    utcDateStr = `${utcDate.toISOString().slice(0, 10)}T23:59:59.999Z`;
  } else {
    utcDateStr = `${utcDate.toISOString().slice(0, 10)}T00:00:00.000Z`;
  }
  return utcDateStr;
}

export const validationSchema = yup.object().shape({
  startDate: yup
    .date()
    .required('This field is required.')
    .max(today),
  endDate: yup
    .date()
    .required('This field is required.')
    .max(today)
    .when('startDate', (startDate, schema) => (startDate && schema.min(startDate))),
});

/* Taken from https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/ */
function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

export async function handleSubmit({ api, VIN, token, onLogout, onError, onSuccess }, values, { setErrors, setSubmitting }) {
  try {
    setSubmitting(true);
    const { startDate, endDate } = values;
    const startDateString = normalizeDate(new Date(startDate));
    const endDateString = normalizeDate(new Date(endDate), true);
    const data = await api.downloadVehicleData(token, { VIN, startDate: startDateString, endDate: endDateString });
    const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    downloadBlob(blob, `VehicleData_${startDate}_to_${endDate}.xlsx`);
    setSubmitting(false);
  } catch (err) {
    if (err instanceof api.errors.Error400) {
      const { fieldErrors, nonFieldErrors } = err.errors;
      setErrors(fieldErrors);
      if (nonFieldErrors) { onError(nonFieldErrors[0]); }
    } else if (err instanceof api.errors.Error401) {
      onLogout();
    } else if (err instanceof api.errors.Error404) {
      onError('No data found!');
    } else {
      onError();
    }
  }
}
