import { useEffect, useState } from 'react';
const isEmpty = require('lodash.isempty');

/**
 * Parse non nested key value pairs in data field.
 */
function parseVehicleData(data) {
  const vehicleData = {};
  const rootDataObject = {};
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'object' && data[key] !== null) {
      vehicleData[key] = data[key];
    } else {
      rootDataObject[key] = data[key];
    }
  });
  if (!isEmpty(rootDataObject)) {
    vehicleData['Data'] = rootDataObject;
  }
  return isEmpty(vehicleData) ? null : vehicleData;
}

function useVehicleData(ws, VIN, token) {
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);

  function onOpen() {
    setIsLoading(false);
  }

  function onMessage({ data }) {
    const dataObj = JSON.parse(data);
    const { event } = dataObj;
    if (event === 'vehicleDataUpdate') {
      setVehicleData(parseVehicleData(dataObj.data || {}));
    }
  }

  useEffect(() => {
    if (VIN) {
      setIsLoading(true);
      const eventHandlers = { onOpen, onMessage };
      const { close } = ws.connect(VIN, token, eventHandlers);

      return () => {
        setIsLoading(true);
        close();
      }
    }
  }, [VIN, token, ws]);

  return { isLoading, vehicleData };
}

export { useVehicleData };
