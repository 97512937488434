export default function makeWS(WS_URL) {
  let timeout = 250;

  function connect(VIN, token, eventHandlers) {
    let forceClosed = false;
    let ws = new WebSocket(`${WS_URL}?VIN=${VIN}&token=${token}`);
    const { onOpen, onMessage, onClose, onError } = eventHandlers;
    ws.onopen = () => onOpen && onOpen();

    ws.onmessage = (e) => onMessage && onMessage(e);

    ws.onclose = (e) => {
      onClose && onClose(e);
      if (!forceClosed) {
        setTimeout(function() {
          if (!forceClosed) { connect(VIN, token, eventHandlers); };
        }, Math.min(10000, timeout += timeout));
      }
    };

    ws.onerror = function(err) {
      onError && onError(err);
      ws.close();
    };

    function send(message) {
      if (ws) { ws.send(message); }
    }

    function close() {
      forceClosed = true;
      if (ws) { ws.close(); };
    }

    return { send, close };
  }

  return {
    connect,
  }
}
