import urljoin from 'url-join';

const ROOT = process.env.PUBLIC_URL || '/';
const LOGIN = urljoin(ROOT, 'login');
const HOME = ROOT;
const UPDATES = urljoin(HOME, 'updates');
const OTA_LIST = urljoin(HOME, 'otaList');
const VEHICLE_DATA = ROOT;
const DOWNLOAD_VEHICLE_DATA = urljoin(ROOT, 'download');
const TRACK_VEHICLE = urljoin(ROOT, 'track');
const SECURITY_MANUAL = urljoin(ROOT, 'securityManual');

export default Object.freeze({
  ROOT,
  LOGIN,
  HOME,
  UPDATES,
  OTA_LIST,
  VEHICLE_DATA,
  DOWNLOAD_VEHICLE_DATA,
  TRACK_VEHICLE,
  SECURITY_MANUAL,
});
