import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import ViewListIcon from '@material-ui/icons/ViewList';

import { useHistory } from 'react-router-dom';

import URLS from '../../urls';

export default function MainListItems() {
  const history = useHistory();

  return (
    <List>
      <div>
        <ListItem button onClick={() => history.push(URLS.VEHICLE_DATA)} title="Home">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => history.push(URLS.DOWNLOAD_VEHICLE_DATA)} title="Download">
          <ListItemIcon>
            <GetAppIcon />
          </ListItemIcon>
          <ListItemText primary="Download" />
        </ListItem>
        <ListItem button onClick={() => history.push(URLS.TRACK_VEHICLE)} title="Track Vehicle">
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Track Vehicle" />
        </ListItem>
        <ListItem button onClick={() => history.push(URLS.SECURITY_MANUAL)} title="Security Manual">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Security Manual" />
        </ListItem>
      </div>
    </List>
  );

};
