import { useState } from 'react';

function useLogin() {
  const [ token, setToken ] = useState(localStorage.getItem('token'));

  function onLogin(newToken) {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  }

  function onLogout() {
    localStorage.removeItem('token');
    setToken(null);
  }

  return { token, onLogin, onLogout };
}

export { useLogin };
