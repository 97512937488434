import React from 'react';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { CircularProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import {Field, Form, Formik } from 'formik';

import CommonTextField from '../../CommonTextField';

import { handleSubmit, validationSchema } from './formHandlers.js';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
  },
  skeleton: {
    width: '100%',
    height: '100px',
  },
  input: {
    display: 'none',
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  progressBar: {
    width: '100%',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function DownloadVehicleData(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => setIsLoading(false), []);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (isLoading) {
    return (
      <>
        <Skeleton className={classes.skeleton} variant="rect" />
      </>
    )
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Formik
          initialValues={{startDate: '', endDate: ''}}
          onSubmit={(...args) => handleSubmit(props, ...args)}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={4}>
                  <Field name="startDate">
                    {(formikProps) => (
                      <CommonTextField
                        id="startDate"
                        label="startDate"
                        name="startDate"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { max: new Date(today.getTime() - (today.getTimezoneOffset() * 60000 )).toISOString().split("T")[0] },
                        }}
                        formikProps={formikProps}
                        autoFocus
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                  <Field name="endDate">
                    {(formikProps) => (
                      <CommonTextField
                        id="endDate"
                        label="endDate"
                        name="endDate"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { max: new Date(today.getTime() - (today.getTimezoneOffset() * 60000 )).toISOString().split("T")[0] },
                        }}
                        formikProps={formikProps}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.wrapper}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};
