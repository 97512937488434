import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

function CommonTextField(props) {
  const { formikProps, ...fieldProps } = props;
  const { field, meta } = formikProps;
  return ( 
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
      required
      {...fieldProps}
      {...field}
    />
  );
}

CommonTextField.propTypes = {
  formikProps: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  InputProps: PropTypes.object,
  autoFocus: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default CommonTextField;
