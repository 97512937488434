export async function handleSubmit({ api, token, onLogout, onError, onSuccess }, values, { setErrors }) {
  try {
    if (!values.securityManual) { return onError('Please provide security manual file.'); }
    await api.postSecurityManual(token, values);
    onSuccess('Successfully uploaded new security manual.');
  } catch (err) {
    if (err instanceof api.errors.Error400) {
      const { fieldErrors, nonFieldErrors } = err.errors;
      setErrors(fieldErrors);
      if (nonFieldErrors) { onError(nonFieldErrors[0]); }
    } else if (err instanceof api.errors.Error401) {
      onLogout();
    } else {
      onError();
    }
  }
}
