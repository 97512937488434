import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import {Field, Form, Formik } from 'formik';
import { Redirect } from 'react-router-dom';

import CommonTextField from '../CommonTextField';
import { handleSubmit, validationSchema } from './formHandlers.js';
import URLS from '../../urls';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        HummingbirdEV
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();

  if (props.token) {
    return <Redirect to={URLS.HOME} />
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Formik
            initialValues={{'username': '', 'password': ''}}
            onSubmit={(...args) => handleSubmit(props, ...args)}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form className={classes.form} noValidate>
                <Field name="username">
                  {(formikProps) => (
                    <CommonTextField
                      id="username"
                      label="Username"
                      name="username"
                      autoComplete="email"
                      autoFocus
                      formikProps={formikProps}
                    />
                  )}
                </Field>
                <Field name="password">
                  {(formikProps) => (
                    <CommonTextField
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      formikProps={formikProps}
                    />
                  )}
                </Field>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Sign In
                </Button>
              </Form>
            )}
          </Formik>
        </Paper>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
